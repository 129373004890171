import React from "react";
import { graphql } from "gatsby";
import Page from '../components/Page/page';
import './news-template.css';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  // if you remove graphql below, this totally breaks
  location
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  return (
    <Page location={location} 
        animateHeader
        seoTitle={frontmatter.title}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}>
        <div
          className="news-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
    </Page>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        subtitle
      }
    }
  }
`;
